import axios from 'axios';

import { Constants } from "../settings/Constants";

const api = async (method, url, data, errorHandlingContext = {}) => {

  // soon wont use auth token from session
  let authToken = localStorage.getItem(Constants.SESSION_KEY_USER_TOKEN);
  console.log(authToken);
  let options = {
    method,
    url
  };
  if (authToken) {
    options.headers = { Authorization: `Bearer ${authToken}` };
  }

  if (data) {
    options.data = data;
  }
  let apiResult = await axios(options).catch(createApiErrorHandler(errorHandlingContext));
  console.log(apiResult)
  return apiResult;
};

const createApiErrorHandler = errorHandlingContext => {
  return axiosError => {
    console.log(axiosError)
    let { customHandler } = errorHandlingContext;
    if (!axiosError.response) {
      console.log(JSON.stringify(axiosError));
      showMessage(errorHandlingContext, 'Network Error: No Response');
      return;
    }

    if (!axiosError.response.data) {
      // Not reaching the API Server
      showMessage(errorHandlingContext, `Network Error ${axiosError.response.status}`);
      return;
    }

    if (!axiosError.response.data.error) {
      // Got response from API Server but error is not specified in expected way
      console.log(JSON.stringify(axiosError));
      showMessage(errorHandlingContext, `Network Error ${axiosError.response.status}`);
      return;
    }

    let apiError = axiosError.response.data.error;

    if (apiError && apiError.action && apiError.action === 'logout'){
      localStorage.removeItem(Constants.SESSION_KEY_USER_TOKEN);
    }

    if (customHandler){
      customHandler(apiError);
      return;
    }

    showMessage(errorHandlingContext, `Error FINAL`);
    return;
  };
};

const showMessage = (errorHandlingContext, message) => {
  let { requestDialog, makeSnackbar, customHandler } = errorHandlingContext;
  if (customHandler) {
    customHandler({ message });
  } else if (requestDialog) {
    requestDialog({
      title: 'Error',
      text: message,
      buttons: [
        {
          text: 'OK'
        }
      ]
    });
  }
};

export default api;