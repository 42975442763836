import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, ButtonGroup } from 'react-bootstrap';
import { Translate, withLocalize } from 'react-localize-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';

import api from '../../services/api';
import config from '../../settings/config';
import { Constants } from '../../settings/Constants';
import { Utils } from '../../Utils/Utils';

import './UserProvisionPage.css';

const randomString = require('randomstring');

class UserProvisionPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: '',
      partnerName: '',
      orderNumber: '',
      periodFrom: '',
      periodTo: '',
      name: '',
      password: '',
      campaignKey: '',
      existingRecord: false,
      searchedRecord: false
    };
  }

  componentDidMount() {
    var userToken = localStorage.getItem(Constants.SESSION_KEY_USER_TOKEN);
  }

  onGeneratePasswordHandler = () => {
    this.setState({
      password: randomString.generate({
        length: 16,
        charset: 'alphanumeric'
      })
    });
  };

  onGenerateCampaignKeyHandler = () => {
    this.setState({
      campaignKey: randomString.generate({
        length: 8,
        charset: 'alphanumeric'
      })
    });
  };

  searchExisitingRecord = async () => {
    let getUserUrl = `${config.apiUrl}/users`;
    let usersData = await api('get', getUserUrl);
    let exisitngUser = null;

    if (usersData) {
      if (usersData.data.ok) {
        let users = usersData.data.users;
        for (let user of users) {
          if (user.id.toString() === this.state.userId) {
            exisitngUser = user;
            break;
          }
        }
      }
    }

    if (exisitngUser) {
      this.setState({
        name: exisitngUser.name,
        campaignKey: exisitngUser.campaignKey,
        partnerName: exisitngUser.partnerName,
        orderNumber: exisitngUser.orderNumber,
        periodFrom: Utils.convertUtcToLocalDate(exisitngUser.periodStart),
        periodTo: Utils.convertUtcToLocalDate(exisitngUser.periodEnd),
        searchedRecord: true
      });
    }
  };

  formValidation = () => {
    let { showPromptMessage } = this.props;

    if (this.state.name === undefined || this.state.name === null || this.state.name === '') {
      showPromptMessage({ key: 'error.empty-username' });
      return false;
    }

    if (this.state.password === undefined || this.state.password === null || this.state.password === '') {
      showPromptMessage({ key: 'error.empty-password' });
      return false;
    }

    if (this.state.partnerName === undefined || this.state.partnerName === null || this.state.partnerName === '') {
      showPromptMessage({ key: 'error.empty-partneName' });
      return false;
    }

    if (this.state.orderNumber === undefined || this.state.orderNumber === null || this.state.orderNumber === '') {
      showPromptMessage({ key: 'error.empty-orderNumber' });
      return false;
    }

    if (this.state.periodFrom === undefined || this.state.periodFrom === null || this.state.periodFrom === '') {
      showPromptMessage({ key: 'error.empty-periodFrom' });
      return false;
    }

    if (this.state.periodTo === undefined || this.state.periodTo === null || this.state.periodTo === '') {
      showPromptMessage({ key: 'error.empty-periodTo' });
      return false;
    }

    if (this.state.periodTo.getTime() < this.state.periodFrom.getTime()) {
      showPromptMessage({ key: 'error.periodTo-earlier-than-periodFrom' });
      return false;
    }

    if (this.state.campaignKey === undefined || this.state.campaignKey === null || this.state.campaignKey === '') {
      showPromptMessage({ key: `error.empty-campaignKey` });
      return false;
    }
  };

  submitForm = async () => {
    if (this.formValidation() === false) {
      return;
    }

    if (this.state.existingRecord) {
      // update exisiting user

      let updateUserUrl = `${config.apiUrl}/users/${this.state.userId}`;
      let userData = await api(
        'put',
        updateUserUrl,
        {
          name: this.state.name,
          password: this.state.password,
          partnerName: this.state.partnerName,
          orderNumber: this.state.orderNumber,
          periodStart: Utils.parseDateToMySQLDatetime(this.state.periodFrom),
          periodEnd: Utils.parseDateToMySQLDatetime(this.state.periodTo),
          campaignKey: this.state.campaignKey
        },
        { customHandler: this.fail }
      );
      if (userData) {
        this.props.showPromptMessage('Success', 'success');
        this.loadUserData(userData);
      }
    } else {
      let createUserUrl = `${config.apiUrl}/users`;
      let userData = await api(
        'post',
        createUserUrl,
        {
          name: this.state.name,
          password: this.state.password,
          partnerName: this.state.partnerName,
          orderNumber: this.state.orderNumber,
          periodStart: Utils.parseDateToMySQLDatetime(this.state.periodFrom),
          periodEnd: Utils.parseDateToMySQLDatetime(this.state.periodTo),
          campaignKey: this.state.campaignKey
        },
        { customHandler: this.fail }
      );
      if (userData) {
        this.props.showPromptMessage('Success', 'success');
        this.loadUserData(userData);
      }
    }
  };

  loadUserData = respData => {
    if (respData.data.ok) {
      let user = respData.data.user;
      this.setState({
        userId: user.id,
        partnerName: user.partnerName,
        orderNumber: user.orderNumber,
        periodFrom: Utils.convertUtcToLocalDate(user.periodStart),
        periodTo: Utils.convertUtcToLocalDate(user.periodEnd),
        name: user.name,
        password: '',
        campaignKey: user.campaignKey,
        existingRecord: true
      });
    }
  };

  clearAll = () => {
    this.setState({
      userId: '',
      partnerName: '',
      orderNumber: '',
      periodFrom: '',
      periodTo: '',
      name: '',
      password: '',
      campaignKey: '',
      existingRecord: false,
      searchedRecord: false
    });
  };

  fail = error => {
    console.log(`${JSON.stringify(error)}`);
    this.props.showPromptMessage({key:`error.${error.message}`});
  };

  logout = () => {
    localStorage.removeItem(Constants.SESSION_KEY_USER_TOKEN);
    this.props.history.push('/');
  };

  onExisitingUserChange = ev => {
    if(ev.target.value === "new"){
      this.clearAll()
    }
    this.setState({ existingRecord: (ev.target.value==="exisiting") });
  }

  render() {
    return (
      <Container>
        <Row className="page-title">
          <Col>
            <h2><Translate id="userProvision.title"/></h2>
          </Col>
        </Row>
        <div className="sm-line-break"/>
        <Row className="page-title">
          <Col>
            <ButtonGroup aria-label="Exisiting User">
              <Button variant="secondary" onClick={this.onExisitingUserChange} active={!this.state.existingRecord} value="new">
                <Translate id="userProvision.new-user"/>
              </Button>
              <Button variant="secondary" onClick={this.onExisitingUserChange} active={this.state.existingRecord} value="exisiting">
                <Translate id="userProvision.existing-user"/>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="sm-line-break"/>
        <Row>
          <Col xs={3}>
            <Translate id="userProvision.userId" />
          </Col>
          <Col xs={6}>
            <Form.Control
              className="custom-input-field"
              value={this.state.userId}
              onChange={ev => this.setState({ userId: ev.target.value })}
              disabled={!(this.state.existingRecord && !this.state.searchedRecord)}
            />
          </Col>
          <Col xs={3}>
            <Button onClick={this.searchExisitingRecord} className="custom-button" disabled={!(this.state.existingRecord && !this.state.searchedRecord)}>
              <Translate id="userProvision.partnerNameSearch" />
            </Button>
          </Col>
        </Row>
        <div className="sm-line-break" />
        <Form.Group as={Row} controlId="formPartnerName">
          <Form.Label column sm="3">
            <Translate id="userProvision.partnerName" />
          </Form.Label>
          <Col sm="9">
            <Form.Control className="custom-input-field" value={this.state.partnerName} onChange={ev => this.setState({ partnerName: ev.target.value })} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formOrderNumber">
          <Form.Label column sm="3">
            <Translate id="userProvision.orderNumber" />
          </Form.Label>
          <Col sm="9">
            <Form.Control className="custom-input-field" value={this.state.orderNumber} onChange={ev => this.setState({ orderNumber: ev.target.value })} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formPeriod">
          <Form.Label column sm="3">
            <Translate id="userProvision.period" />
          </Form.Label>
          <Col xs={3}>
            <DatePicker
              placeholderText={this.props.placeHolderText || 'MM/DD/YYYY'}
              onChange={ev =>
                this.setState(prevState => {
                  let clear = false;
                  if (prevState.periodFrom && prevState.periodTo && prevState.periodTo.getTime) {
                    if (ev.getTime() > prevState.periodTo.getTime()) {
                      clear = true;
                    }
                  }

                  return {
                    periodFrom: ev,
                    periodTo: clear ? null : prevState.periodTo
                  };
                })
              }
              selected={this.state.periodFrom}
              className="custom-input-field form-control"
            />
          </Col>
          <Col xs={1}>
            <Translate id="userProvision.periodTo" />
          </Col>
          <Col xs={3}>
            <DatePicker
              placeholderText={this.props.placeHolderText || 'MM/DD/YYYY'}
              onChange={ev => this.setState({ periodTo: ev })}
              selected={this.state.periodTo}
              className="custom-input-field form-control"
              minDate={this.state.periodFrom ? this.state.periodFrom : null}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formName">
          <Form.Label column sm="3">
            <Translate id="userProvision.name" />
          </Form.Label>
          <Col sm="9">
            <Form.Control className="custom-input-field" value={this.state.name} onChange={ev => this.setState({ name: ev.target.value })} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formPassword">
          <Form.Label column sm="3">
            <Translate id="userProvision.password" />
          </Form.Label>
          <Col xs={5}>
            <Form.Control className="custom-input-field" value={this.state.password} />
          </Col>
          <Col xs={4}>
            <Button className="custom-button" onClick={this.onGeneratePasswordHandler}>
              <Translate id="userProvision.generate" />
            </Button>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formCampaignKey">
          <Form.Label column sm="3">
            <Translate id="userProvision.campaignKey" />
          </Form.Label>
          <Col xs={5}>
            <Form.Control className="custom-input-field" value={this.state.campaignKey} />
          </Col>
          <Col xs={4}>
            <Button className="custom-button" onClick={this.onGenerateCampaignKeyHandler}>
              <Translate id="userProvision.generate" />
            </Button>
          </Col>
        </Form.Group>
        <div className="sm-line-break" />
        <Row>
          <Col xs={6}></Col>
          <Col xs={2}>
            <Button onClick={this.clearAll} className="custom-button">
              <Translate id="userProvision.clear" />
            </Button>
          </Col>
          <Col xs={2}>
            <Button onClick={this.submitForm} className="custom-button">
              <Translate id="userProvision.save" />
            </Button>
          </Col>
          <Col xs={2}>
            <Button onClick={this.logout} className="custom-button">
              <Translate id="userProvision.close" />
            </Button>
          </Col>
        </Row>
        <div className="sm-line-break"/>
      </Container>
    );
  }
}

export default withLocalize(UserProvisionPage);