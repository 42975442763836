import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize, setActiveLanguage } from 'react-localize-redux';
import IdleTimer from 'react-idle-timer';
import Routes from './routes/Routes';
import enTranslations from './translations/lang_en.json';
import zhTranslations from './translations/lang_zh.json';
import config from './settings/config';
import { Constants } from './settings/Constants';
import './App.css';
import './css/FontAwesome/all.min.css';
import Swal from 'sweetalert2';

import background from './img/login-BG-954px.jpg';

class App extends Component {
  constructor(props) {
    super(props);

    this.idleTimer = null;
    this.onActive = this.onActiveHandler.bind(this);
    this.onIdle = this.onIdleHandler.bind(this);

    let sessionUserData = localStorage.getItem(Constants.SESSION_KEY_USER_TOKEN);

    this.state = {
      isLanguageUpdated: false,
      activeLanguage: localStorage.getItem(Constants.ACTIVE_LANG_CODE)
    };

    const languages = [{ name: 'EN', code: 'en' }, { name: '繁', code: 'zh' }];

    const defaultLanguage = 'zh' || localStorage.getItem(Constants.ACTIVE_LANG_CODE) || languages[0].code;
    setActiveLanguage(defaultLanguage);

    this.props.initialize({
      languages,
      options: {
        renderToStaticMarkup,
        renderInnerHtml: true,
        defaultLanguage
      }
    });

    this.props.addTranslationForLanguage(enTranslations, 'en');
    this.props.addTranslationForLanguage(zhTranslations, 'zh');
  }

  componentDidMount() {}

  componentWillUpdate(prevProps, prepStatus) {
    localStorage.setItem('Update_LANG', prevProps.activeLanguage.code);
  }

  componentDidUpdate(prevProps) {
    const prevLangCode = prevProps.activeLanguage && prevProps.activeLanguage.code;
    const curLangCode = this.props.activeLanguage && this.props.activeLanguage.code;
    const hasLanguageChanged = prevLangCode !== curLangCode;

    if (hasLanguageChanged) {
      this.setState({ isLanguageUpdated: true, activeLanguage: curLangCode });
      localStorage.setItem(Constants.ACTIVE_LANG_CODE, curLangCode);
    }
  }

  resetLoginState = () => {
    localStorage.removeItem(Constants.SESSION_KEY_USER_TOKEN);
  };

  onActiveHandler(e) {
    /*console.log('user is active', e)
    console.log('time remaining', this.idleTimer.getRemainingTime())*/
  }

  onIdleHandler(e) {
    if (this.state.isLoggedIn) {
      //console.log('user is logged in and idle for '+config.loginIdleTimeout);
      this.resetLoginState();
    }
  }
  promptMessage = (message, msgType, callback=null) => {
    if (message.key) {
      let keySplits = message.key.split(/\./g);
      let html = { en: enTranslations, zh: zhTranslations }[this.state.activeLanguage];
      for (let keySplit of keySplits) {
        html = html[keySplit];
      }

      if (message.data) {
        for(let key of Object.keys(message.data)) {
          html = html.replace(`%%${key}%%`, message.data[key]);
        }
      }

      Swal.fire({
        html,
        type: msgType === undefined ? 'error' : msgType
      }).then(result => {
        if (callback) {
          callback(result);
        }
      });
    } else {
      Swal.fire({
        html: message,
        type: msgType === undefined ? 'error' : msgType
      }).then(result => {
        if (callback) {
          callback(result);
        }
      });
    }
  };

  promptDialog = (message, msgType, callback) => {
    if (message.key) {
      let keySplits = message.key.split(/\./g);
      let html = { en: enTranslations, zh: zhTranslations }[this.state.activeLanguage];
      for (let keySplit of keySplits) {
        html = html[keySplit];
      }
      Swal.fire({
        html,
        type: msgType === undefined ? 'warning' : msgType,
        showCancelButton: true,
        confirmButtonClass: 'btn-danger',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        closeOnConfirm: false,
        closeOnCancel: false
      }).then(result => {
        if (callback) {
          callback(result);
        }
      });
    } else {
      Swal.fire({
        html: message,
        type: msgType === undefined ? 'warning' : msgType,
        showCancelButton: true,
        confirmButtonClass: 'btn-danger',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel'
      }).then(result => {
        if (callback) {
          callback(result);
        }
      });
    }
  };

  render() {
    const childProps = {
      showPromptMessage: this.promptMessage,
      showPromptDialog: this.promptDialog
    };

    return (
      <IdleTimer
        ref={ref => {
          this.idleTimer = ref;
        }}
        element={document}
        onActive={this.onActive}
        onIdle={this.onIdle}
        timeout={config.loginIdleTimeout}
      >
        <div className="App" style={{ background: `no-repeat url(` + background + `)`, backgroundSize: `100%` }}>
          <div className="App-content margin-top-dropdown" id="App-content">
            <Routes childProps={childProps} />
          </div>
          <div className="App-footer">{/* <Footer /> */}</div>
        </div>
      </IdleTimer>
    );
  }
}

export default withRouter(withLocalize(App));
