import React, { Component } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { Row, Col, Container, Button, ButtonGroup, Form } from 'react-bootstrap';
import config from '../../settings/config';
import { Constants } from '../../settings/Constants';
import api from '../../services/api';
import { Utils } from '../../Utils/Utils';

// import './LoginPage.css';
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: 'user'
    };
  }

  componentDidMount() {
    if (localStorage.getItem(Constants.SESSION_KEY_USER_TOKEN)) {
      let jwtTokenInfo = Utils.parseJwt(localStorage.getItem(Constants.SESSION_KEY_USER_TOKEN));

      if (jwtTokenInfo.type === 'user') {
        this.props.history.push(`/dashboard`);
      } else {
      }
    }
  }

  onUserTypeChange = evt => {
    this.setState({ userType: evt.target.value });
  };

  login = async () => {
    let name = document.getElementById('loginFormName').value;
    let password = document.getElementById('loginFormPassword').value;
    let loginUrl = `${config.apiUrl}/${this.state.userType}-sessions`;
    let loginData = await api('post', loginUrl, { name, password }, { customHandler: this.onLoginFailed });
    if (loginData) {
      this.loadUserData(loginData);
    }
  };

  loadUserData = respData => {
    if (respData.data.ok) {
      localStorage.setItem(Constants.SESSION_KEY_USER_TOKEN, respData.data.authToken);
      let jwtTokenInfo = Utils.parseJwt(respData.data.authToken);

      if (jwtTokenInfo.type === 'user') {
        this.props.history.push(`/dashboard`);
      } else if (jwtTokenInfo.type === 'staff') {
        this.props.history.push(`/users`);
      }
    }
  };

  onLoginFailed = error => {
    let { showPromptMessage } = this.props;
    showPromptMessage({ key: `error.${error.message}` });
  };

  render() {
    return (
      <Container>
        <Row className="page-title">
          <Col>
            <h2>
              <Translate id="login.title" />
            </h2>
          </Col>
        </Row>
        <div className="sm-line-break" />
        <Row className="page-title">
          <Col>
            <ButtonGroup aria-label="User Type">
              <Button variant="secondary" onClick={this.onUserTypeChange} active={this.state.userType === 'user'} value="user">
                <Translate id="login.user" />
              </Button>
              <Button variant="secondary" onClick={this.onUserTypeChange} active={this.state.userType === 'staff'} value="staff">
                <Translate id="login.staff" />
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="sm-line-break" />
        <Row>
          <Col sm={{ span: 6, offset: 3 }}>
            <Form id="loginForm">
              <Form.Group controlId="loginFormName">
                <Form.Label>
                  <Translate id={this.state.userType === 'user' ? 'login.name' : 'login.staffName'} />
                </Form.Label>
                <Form.Control type="text" />
              </Form.Group>

              <Form.Group controlId="loginFormPassword">
                <Form.Label>
                  <Translate id="login.password" />
                </Form.Label>
                <Form.Control type="password" />
              </Form.Group>

              <Button variant="dark" onClick={this.login}>
                <Translate id="login.button" />
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withLocalize(LoginPage);
