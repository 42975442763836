import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import './TncPanel.css'

const TncPanel = props => {
  return (
    <Fragment>
      <p>
        <span>
          MyTV SUPER 2<sup>nd</sup> PARTY DATA ONBOARDING TERMS AND CONDITIONS
        </span>
      </p>
      <p>
        <span>(last updated on [09112019])</span>
      </p>
      <p>
        <span>
          The terms and conditions set forth&nbsp;below (these &ldquo;Terms and
          Conditions&rdquo;) govern your use of the myTV SUPER 2<sup>nd</sup>
        </span>
        <span>&nbsp;Party Data Onboarding Service (</span>
        <span>our &ldquo;Service&rdquo;</span>
        <span>) via our platform at&nbsp;</span>
        <span>
          <a href="https://www.google.com/url?q=https://2ndparty.mytvsuper.com/&amp;sa=D&amp;ust=1572845712644000">
            https://2ndparty.mytvsuper.com/
          </a>
        </span>
        <span>&nbsp;(</span>
        <span>&ldquo;our Platform&rdquo;</span>
        <span>
          ). By registering or using our Service, you agree to be bound by (i)
          these Terms and Conditions; and (ii)
        </span>
        <span>&nbsp;</span>
        <span>the Terms and Conditions in the Rate Card at </span>
        <span>
          <a href="https://www.google.com/url?q=https://ad.mytvsuper.com/category/ratecard/terms-conditions/&amp;sa=D&amp;ust=1572845712644000">
            https://ad.mytvsuper.com/category/ratecard/terms-conditions/
          </a>
        </span>
        <span>&nbsp;(</span>
        <span>&ldquo;Advertising T&amp;C&rdquo;)</span>
        <span>&nbsp;(collectively </span>
        <span>this &ldquo;Agreement&rdquo;</span>
        <span>
          ). These Terms and Conditions and the Advertising T&amp;C constitute a
          binding legal contract between you and MyTV Super Limited (&ldquo;
        </span>
        <span>us</span>
        <span>
          &rdquo;) and govern your access to and use of our Service. If you do
          not agree to any of the terms in this Agreement, please do not access
          or otherwise use our Platform or Service or any information contained
          in our Service. Your use of our Service will be deemed to be your
          agreement to abide by these this Agreement. We may make changes to the
          content and services offered on or through our Service at any time
          without notice. We may change these Terms and Conditions at any time
          by posting updated terms of use on our Platform. If any modification
          is unacceptable to you, please cease using our Service. If you do not
          cease using our Service, you will be deemed to have accepted those
          changes. You should regularly check these Terms and Conditions.
        </span>
      </p>
      <p>
        <span>
          You represent that you are 18 years old or above. You further
          represent that if you are registering as an agent on behalf of a
          company or other legal entity, you have all authority necessary to
          bind such company or other legal entity to this Agreement. If you
          are&nbsp;acting for a company or other legal entity, the terms
          &ldquo;You&rdquo; and &ldquo;your&rdquo; shall also refer to the
          company or other legal entity that you represent. You must have
          permission to place advertisements on their behalf and agree as
          follows: &nbsp;(i) You represent and warrant that you have the
          authority to bind the company or legal entity you act for to this
          Agreement; and (ii) If the company or legal entity you represent
          violates any terms in this Agreement, we may hold you responsible for
          that violation jointly and severally with the company or legal entity
          concerned.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_17-0 start" start="1">
        <li>
          <span>Definitions</span>
        </li>
      </ol>
      <Row>
        <Col xs="4">"2nd Party Hash Data"</Col>
        <Col xs="8">
          means Personal Data such as [(a) telephone number; and (b) email
          address] collected by you from your users and clients and which are
          hashed by you by using our hash tool and/or automatic hashing system.
        </Col>
      </Row>
      <Row>
        <Col xs="4">"MYTV Super Data"</Col>
        <Col xs="8">
          means Personal Data such as [Device ID] collected by us from our users
          and clients.
        </Col>
      </Row>
      <Row>
        <Col xs="4">"MyTV Super"</Col>
        <Col xs="8">
          means the digital online services provided by MyTV Super Limited.
        </Col>
      </Row>
      <Row>
        <Col xs="4">"Segments"</Col>
        <Col xs="8">
          means segments compiled and generated by us based on and upon matching
          of 2nd Party Hash Data with MYTV Super Data
        </Col>
      </Row>
      <Row>
        <Col xs="4">"Campaign"</Col>
        <Col xs="8">means advertising campaign on MyTV Super.</Col>
      </Row>
      <Row>
        <Col xs="4">"Intellectual Property Rights"</Col>
        <Col xs="8">
          means all forms of proprietary rights, titles and interests relating
          to patents, copyrights, trademarks, trade dresses, trade secrets,
          algorithms, know-how, mask works, moral rights, and all similar rights
          of every type that may exist now or in the future, and in any
          jurisdiction.
        </Col>
      </Row>

      <Row>
        <Col xs="4">"Personal Data"</Col>
        <Col xs="8">
          unless otherwise defined in the specific terms and conditions, means
          “personal data” as defined in the Personal Data (Privacy) Ordinance
          (Chapter 486) under the laws of Hong Kong.{" "}
        </Col>
      </Row>
      
      <ol className="c8 lst-kix_list_17-0" start="2">
        <li className="c21 c59">
          <span>Grant of Rights</span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_18-0 start" type="a">
        <li>
          <span>
            You hereby grant us a worldwide, non-exclusive, royalty free license
            to
          </span>
          <span>&nbsp;access and use 2</span>
          <span>nd</span>
          <span>&nbsp;Party Hash Data to</span>
          <span>:</span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_19-0 start" type="i">
        <li className="c45 c47 c56">
          <span>
            onboarding onto our Platform for matching with MYTV Super Data;
          </span>
        </li>
        <li>
          <span>
            generate Segments from the aforesaid matched data and provide the
            Segments to you for your Campaign purposes on MyTV Super;
          </span>
        </li>
        <li>
          <span>analyze and improve performance of our Platform; and</span>
        </li>
        <li>
          <span>
            write and compile case studies regarding your use of our Service and
            to publish the same.
          </span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_18-0" type="a" start="2">
        <li>
          <span>
            We hereby grant you the right to access our Platform and to use the
            Segments solely for the purposes of managing your Campaign on myTV
            SUPER and reviewing analysis associated with your Campaign. Nothing
            herein shall be deemed as conferring any rights to you with respect
            to any of our Intellectual Property Rights to the Segments, our
            Service or Platform.
          </span>
          <span>&nbsp;</span>
          <span>
            You shall not disclose or make available to a third party any
            portion of the Segment or Service or Platform in a manner that
            competes with us or our Service Platform; or in exchange for
            compensation of any kind.{" "}
          </span>
        </li>
      </ol>
      <p>
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_17-0" start="3">
        <li>
          <span>Requirement For Use of Our Service</span>
        </li>
      </ol>
      <p>
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_20-0 start" type="a" start="1">
        <li>
          <span>
            Unless with our prior written permission, our Service is available
            to you only{" "}
          </span>
          <span>
            in Hong Kong. You agree not to use or attempt to use our Service in
            other locations. We may collect your IP address to confirm your
            geographical location.{" "}
          </span>
        </li>
      </ol>
      <p className="c7 c25">
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_20-0" type="a" start="2">
        <li>
          <span>
            Use of our Service requires compatible devices, Internet access, and
            periodic updates (fees imposed by third parties may apply). You
            acknowledge that you are responsible to obtain and maintain at your
            own expense all equipment, systems or software, and services needed
            to access our Service. You shall bear any communication or data
            transmission charges incurred by accessing to our Service and
            updating the software through a third party network.{" "}
          </span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_17-0" start="4">
        <li>
          <span>User Account Registration</span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_21-0 start" type="a" start="1">
        <li>
          <span>
            Before you can access and/or use our Service, you must register as a
            user by providing us with current, complete and accurate information
            as required on our Platform.
          </span>
        </li>
        <li>
          <span>
            The information you provide to us for registration of your user
            account will be dealt with in accordance with our{" "}
          </span>
          <span className="c39 c43">Privacy Statement at </span>
          <span>
            <a href="https://www.google.com/url?q=https://www.mytvsuper.com/en/pics&amp;sa=D&amp;ust=1572845712648000">
              https://www.mytvsuper.com/en/pics
            </a>
          </span>
          <span>.</span>
        </li>
        <li>
          <span>
            We may at times undertake checks to ensure the accuracy of the
            information you have provided to us including contacting you.
          </span>
        </li>
        <li>
          <span>
            We reserve the right not to accept your registration in our sole
            discretion without specifying reason.
          </span>
        </li>
        <li>
          <span>
            Each user can only have one account. You agree to keep your password
            and user account secure and confidential and not to allow anyone
            else to use your user account or password to access our Platform nor
            to do anything which would assist or allow anyone who is not a
            registered user to gain access to our Platform; nor to create
            accounts for others without their permission; nor to create
            additional user accounts for the purpose of abusing the
            functionality of our Platform or other users&rsquo; accounts, nor to
            seek to pass yourself off as another user; nor to do anything that
            jeopardize the security of your account.
          </span>
        </li>
        <li>
          <span>
            If you have reason to believe that someone has unauthorized use of
            your password or user account or has committed any other breach of
            security, please report to us at{" "}
          </span>
          <span>
            <a href="mailto:kenrik.chan@tvb.com.hk">k</a>
          </span>
          <span>
            <a href="mailto:kenrik.chan@tvb.com.hk">enrik.chan@tvb.com.hk</a>
          </span>
          <span>
            &nbsp;immediately for our immediate suspension of your user account
            and/or to take other appropriate actions. You agree that you will be
            responsible to us and to others for all activities that occur under
            your user account. We will not be liable for any loss or damage
            arising from your failure to comply with the terms herein stated or
            from any third party&rsquo;s act without your authorization.
          </span>
        </li>
        <li>
          <span>
            For security reasons, we strongly recommend you to change your
            account password on a regular basis.
          </span>
        </li>
        <li>
          <span>
            You are not allowed to sell, transfer, license or assign your user
            account or any of your rights as a user or under your account to any
            party.
          </span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_17-0" start="5">
        <li>
          <span>Case Studies and Post Campaign Analysis</span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_1-0 start" type="a" start="1">
        <li>
          <span>
            You agree that we may compile one or more case studies based on this
            Agreement and the results of the same. We may also undertake post
            Campaign analysis to measure the effectiveness of a Campaign/or and
            our Service. You agree that we may use the case studies and/or post
            campaign analysis on any platform and in any manner.&nbsp;
          </span>
        </li>
        <li className="c21 c53">
          <span>
            You agree that we own the case studies and any materials generated
            from your use of our Service.
          </span>
        </li>
      </ol>
      <p>
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_17-0" start="6">
        <li>
          <span>Hash Tools and Segments</span>
          <span>:</span>
        </li>
      </ol>
      <p>
        <span>
          You shall use our hash tool or automatic hashing system to hash all
          data collected by you from your users and clients before uploading
          them onto our Platform for our matching purpose. Please do not upload
          or provide any 2
        </span>
        <span>nd</span>
        <span>
          &nbsp;Party data to us without hashing. Please read carefully user
          details of the hash tool and/or the automatic hashing system at
        </span>
        <span>&nbsp;</span>
        <span>
          <a href="https://www.google.com/url?q=https://2ndparty.mytvsuper.com/&amp;sa=D&amp;ust=1572845712649000">
            https://2ndparty.mytvsuper.com/
          </a>
        </span>
        <span>&nbsp;</span>
        <span>before use. </span>
      </p>
      <ol className="c8 lst-kix_list_22-0 start" type="a" start="1">
        <li>
          <span>
            You understand and agree that you are responsible for your own
            actions with respect to hashing data and applying Segments for the
            Campaign. We will not review your activity and we are not
            responsible or liable for any decisions made by you in using our
            tools, the Segments and Service.
          </span>
        </li>
      </ol>
      <p>
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_17-0" start="7">
        <li>
          <span>Intellectual Property Rights</span>
        </li>
      </ol>
      <p className="c7 c25">
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_23-0 start" type="a" start="1">
        <li>
          <span>
            You agree that we own and retain all rights to MyTV Super Data, our
            Service, our Platform and/or the Segments. All trademarks, service
            marks and logos (
          </span>
          <span>the</span>
          <span>&nbsp;&ldquo;</span>
          <span>Marks</span>
          <span>
            &rdquo;) on our Service or Platform are owned by or licensed to us.
          </span>
        </li>
        <li>
          <span>
            You acknowledge that MyTV Super Data, our Service, our Platform, the
            Segments and/or the Marks are protected by copyright, trademark
            laws, and other Intellectual Property Right laws, and you further
            agree that you are being granted with a non-exclusive,
            non-transferrable, limited license, without right of sublicense, to
            access and use our Service, our Platform and/or the Segments
            contained therein in compliance with this Agreement.
          </span>
        </li>
        <li>
          <span>
            Nothing you do on or in relation to our Service, our Platform, the
            Segments, the materials contained therein will transfer any
            intellectual property rights to you or license you to exercise any
            Intellectual Property Rights.
          </span>
        </li>
        <li>
          <span>
            You agree to indemnify, defend, and hold us harmless from any third
            party claims, demands or suits, or any losses, damages, liabilities,
            fines, penalties and expenses (including reasonable attorney&rsquo;s
            fees) related to your use of the Segments as permitted herein or the
            breach of this Agreement.{" "}
          </span>
        </li>
        <li>
          <span>
            We expressly reserve the right to take action against you in the
            event that you infringe any of our intellectual property rights or
            other rights of any person.
          </span>
        </li>
      </ol>
      <p className="c7 c47">
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_17-0" start="8">
        <li>
          <span>Your Representations, Warranties and Undertakings</span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_24-0 start" type="a" start="1">
        <li>
          <span>You represent, warrant and undertake that </span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_25-0 start" type="i" start="1">
        <li>
          <span>
            you have all necessary rights, licenses, and clearances to enter
            into this Agreement;
          </span>
        </li>
        <li>
          <span>
            you have all necessary rights and permissions, including the consent
            of each party whom you collect personal data from, to have their
            personal data being hashed, transferred and uploaded by you onto our
            Platform, and to be processed by us, for use by you and us in
            connection with the Campaign and our Service.{" "}
          </span>
          <span>Please also refer to Section 10 (Personal Data) below</span>
          <span>;</span>
        </li>
        <li>
          <span>
            your use of our Service do not conflict with any agreement with any
            party or any terms of use, privacy policy, representation or other
            agreement you may have made or posted to users;{" "}
          </span>
        </li>
        <li>
          <span>
            you shall comply with all applicable law, decisions, directives,
            guidance, rules or regulations (including data protection and
            privacy laws), applicable industry best practices and
            self-regulatory guidelines or codes.
          </span>
          <span>
            &nbsp;Please also refer to Section 10 (Personal Data) below
          </span>
          <span>; </span>
        </li>
        <li>
          <span>
            you have all necessary rights and permissions to authorize our use
            of the 2
          </span>
          <span>nd</span>
          <span>&nbsp;Party Hash Data as stipulated in this Agreement.</span>
          <span>
            &nbsp;Please also refer to Section 10 (Personal Data) below
          </span>
          <span>;</span>
        </li>
        <li>
          <span>
            you shall maintain a comprehensive online privacy policy for your
            users&rsquo; information at your end and will comply with such
            policy{" "}
          </span>
          <span>
            <a href="https://www.google.com/url?q=http://www.nielsen.com/%2520digitalprivacy&amp;sa=D&amp;ust=1572845712651000">
              a
            </a>
          </span>
          <span>nd providing users with opt-out options. </span>
          <span>Please also refer to Section 10 (Personal Data) below</span>
          <span>;</span>
        </li>
        <li>
          <span>
            you shall only use our Service for purposes permitted in these Terms
            and Conditions;{" "}
          </span>
        </li>
        <li>
          <span>
            you shall not tamper with, hinder the operation of or make
            unauthorized modifications to our Service; or knowingly transmit any
            virus or other disabling feature to the Service;
          </span>
        </li>
        <li>
          <span>
            you shall not download, sell, share, reproduce, copy, distribute,
            publish, modify, prepare derivative works based on our Service or
            the Segments by any means;
          </span>
        </li>
        <li>
          <span>
            you shall not use any robot, spider, scripts, site search, retrieval
            application, or other manual or automatic device or process to
            access, monitor, retrieve, index, &ldquo;data mine&rdquo;, or in any
            way reproduce, disrupt or copy, circumvent the navigational
            structure or presentation of our Service or the Segments, and/or our
            Platform;
          </span>
        </li>
        <li>
          <span>
            you shall not hack, break into, or attempt to hack or break into our
            Service or our Platform, the Segments, and/or any data zones on our
            server(s) or that of any third party in any manner, or access such
            parts of our Service and/or our Platform and/or the Segments which
            you are not authorized to have access to;
          </span>
        </li>
        <li>
          <span>
            you shall not implant into any software or other materials that
            contain any software viruses, worms, time bombs, Trojan horses or
            other harmful or disruptive component;{" "}
          </span>
        </li>
        <li>
          <span>
            you shall not remove any copyright, trademark, or other proprietary
            rights notices contained in our Service, and/or Platform;
          </span>
        </li>
        <li>
          <span>
            you shall not obstruct or interfere with our Service or servers or
            networks connected to our Service, or restrict or inhibit any person
            to use our Service;
          </span>
        </li>
        <li>
          <span>
            you shall not use any device or apparatus which may be harmful to
            our network or third party&rsquo;s equipment (in which case, you
            shall immediate cease the connection of such device or apparatus);
          </span>
        </li>
        <li>
          <span>
            you shall not use or disclose the Segments to any third party;
          </span>
        </li>
        <li>
          <span>
            you shall not copy, publish, distribute, sell, license or otherwise
            make use of our Service, Segments or other materials in any other
            manner or for any other purpose other than for Campaign purpose on
            myTV Super as permitted in this Agreement;
          </span>
        </li>
        <li>
          <span>
            you shall not disclose, distribute or publish any confidential or
            personal information of the data subject obtained via our Service
            without the consent of that data subject;
          </span>
        </li>
        <li>
          <span>
            you shall not use our Service, the Segments and/or our Platform for
            any illegal or immoral purpose.
          </span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_27-0 start" type="a" start="2">
        <li>
          <span>
            You shall indemnify, defend, save, and hold harmless us and our
            parents, subsidiaries, affiliates, representatives, officers,
            directors, agents, affiliates, and employees, from and against any
            and all third party claims, damages, fines, penalties, awards,
            judgments, and liabilities (including reasonable outside
            attorneys&#39; fees and costs) (
          </span>
          <span>collectively, &ldquo;Losses&rdquo;</span>
          <span>
            ) &nbsp;resulting from, arising out of, or related to your breach or
            alleged breach of any of your representations, warranties or
            agreements under this Agreement.
          </span>
        </li>
      </ol>
      <p className="c25 c41">
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_17-0" start="9">
        <li className="c21 c58">
          <span>Disclaimer and Limitation of Liability</span>
        </li>
      </ol>
      <p className="c45 c48">
        <span>
          TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL WE BE
          LIABLE(INCLUDING IN CONTRACT, FOR NEGLIGENCE OR OTHERWISE) TO YOU FOR
          ANY SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, DIRECT, INDIRECT, OR
          CONSEQUENTIAL DAMAGES IN CONNECTION WITH AND WITHOUT LIMITATION TO:
          (1) YOUR USE OF OUR SERVICE, OUR PLATFORM AND THE SEGMENTS; (2) ANY
          UNAVAILABILITY, INTERRUPTION, DELAY IN OPERATION, VIRUS, INTERNET
          ACCESS DIFFICULTIES, OR EQUIPMENT MALFUNCTION IN RELATION TO OUR
          SERVICE OR PLATFORM; (3) ANY FAILURE TO PROVIDE OUR SERVICE, OUR
          PLATFORM OR THE SEGMENTS; (4) ANY ERRORS, OMISSIONS, INACCURACIES; (5)
          YOUR USE OR RELIANCE ON ANY INFORMATION PROVIDED IN RELATION TO OUR
          SERVICE, OUR PLATFORM OR THE SEGMENTS. OUR TOTAL LIABILITY TO YOU
          UNDER THIS AGREEMENT, FROM ALL CAUSES OF ACTION AND UNDER ALL THEORIES
          OF LIABILITY WILL NOT EXCEED THE AMOUNTS ACTUALLY{" "}
        </span>
        <span>IN CONSIDERATION OF THE RELEVANT CAMPAIGN</span>
        <span>. </span>
        <span>
          UNLESS OTHERWISE PROHIBITED BY APPLICABLE LAW, WE SHALL NOT BE LIABLE
          FOR ANY CLAIM BROUGHT MORE THAN ONE YEAR AFTER THE CAUSE OF ACTION
          ACCRUES. &nbsp;In the event applicable law does not permit such
          limitation or exclusion of liability, the limitation or exclusion
          shall be deemed modified so as to be effective to the greatest extent
          permitted.{" "}
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_36-0 start" start="10">
        <li>
          <span>Personal Data</span>
        </li>
      </ol>
      <p className="c7 c25">
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_31-0 start" type="a" start="1">
        <li>
          <span>
            Each party under this Agreement shall comply with all relevant
            obligations under the Personal Data (Privacy) Ordinance (Chapter
            486) (the &ldquo;Ordinance&rdquo;) under the laws of Hong Kong, as
            well as the relevant code of practice and guidance issued by the
            Office of the Privacy Commissioner for Personal Data, Hong Kong.
          </span>
        </li>
        <li>
          <span>
            For purposes of this Section, the terms &ldquo;Controller,&rdquo;
            &quot;Data Subject,&rdquo; &quot;Personal Data,&quot; and
            &quot;Processing&quot; shall have the same meaning as given in the
            EU General Data Protection Regulation (&ldquo;GDPR&rdquo;), and
            their cognate terms shall be construed accordingly:
          </span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_32-0 start" type="i" start="1">
        <li>
          <span>You shall provide us with 2</span>
          <span>nd</span>
          <span>
            &nbsp;Party Hash Data which may include Personal Data. To the extent
            applicable under GDPR or other applicable law, each party
            acknowledges and agrees that it shall be a Controller of the Data
            and shall Process Personal Data in compliance with the terms of the
            Agreement, including any limitations on the use and distribution of
            the Data, and all applicable laws and regulations, including data
            protection laws.{" "}
          </span>
        </li>
        <li>
          <span>
            You confirm that all data, including any Personal Data, provided to
            us under this Agreement has been obtained by you in accordance with
            applicable laws and regulations (including data protection laws and
            regulations).
          </span>
        </li>
        <li>
          <span>
            You will provide notices and obtain Data Subject consent or will
            ensure appropriate privacy notices, regarding the collection and use
            of the user data (including the uses contemplated under this
            Agreement) have been provided to Data Subjects and consents have
            been obtained (including any opt-in consents) as required by
            applicable laws and regulations before making Personal Data
            available to us. The privacy notices shall include: (i) a statement
            of the fact that their data may be used for online behavioral
            advertising (&quot;OBA&quot;) and/or Multi-Site Advertising
            purposes; (ii) language which informs users that third party cookies
            may be delivered to them and that the third party cookies may be
            used to provide a more targeted advertising experience; (iii) a
            description of types of data that are collected for OBA and/or
            Multi-Site Advertising purposes; (iv) an explanation of how, and for
            what purpose, that data will be used or transferred to third
            parties; and (v) the ability to opt-out of third party cookies (for
            example, via a conspicuous link to the eXelate website
            (www.exelate.com/consumer-opt-out/), the Network Advertising
            Initiative website (www.networkadvertising.org), or the DAA website
            (www.aboutads.info)).{" "}
          </span>
        </li>
        <li>
          <span>
            Where websites are directed to users in Europe, you further agree
            that: (1) its privacy policies shall (x) clearly and conspicuously
            notify visitors about the extent, type and purpose of collecting or
            processing of personal information including processing of personal
            information in countries outside of the European Economic Area; (y)
            provides opt-in instructions and choices in connection with the use
            of any relevant online tracking technologies as well as creation of
            user profiles and will obtain consent to the use of cookies at the
            site as required by the EU member country&#39;s implementation of
            the &quot;e-Privacy Directive&quot; as amended or superseded, if
            applicable; and (2) it is in compliance with relevant guidance from
            local data protection authorities within such EU member country,
            industry best practices and self-regulatory guidelines or codes.
          </span>
        </li>
        <li>
          <span>
            You agree to promptly notify us if you cannot comply, or have reason
            to believe you cannot comply with your obligations under this
            Agreement, including your obligations as a Controller of Personal
            Data.
          </span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_18-0" type="a" start="3">
        <li>
          <span>
            The parties agree to use appropriate technical and organizational
            measures to ensure the security of the Personal Data, including
            protection against unauthorized or unlawful processing and against
            accidental loss, destruction or damage, including the implementation
            of appropriate data protection policies.
          </span>
        </li>
        <li>
          <span>
            The parties agree to meet their obligations under applicable data
            protection laws and regulations to: (1) establish and maintain a
            procedure for the exercise of the rights of the individuals whose
            Personal Data are Processed under this Agreement; and (2) ensure
            compliance with the provisions of the Agreement by their personnel
            and by any person accessing or using Personal Data on their behalf,
            including any sub-processors.
          </span>
        </li>
        <li>
          <span>You may not include in 2</span>
          <span>nd</span>
          <span>
            &nbsp;Party Hash Data any Sensitive Consumer Information. We reserve
            the right, in our sole discretion, to not process any data or file
            that contains Sensitive Consumer Information and/or map any User
            Attributes associated with, or categorically related to Sensitive
            Consumer Information. You may not include in 2
          </span>
          <span>nd</span>
          <span>
            &nbsp;Party Hash Data any data related to individuals or households
            who have previously opted-out of targeted digital advertising
            through your website or processes, or other third party privacy
            compliance partners. The terms &ldquo;Sensitive Consumer
            Information&rdquo; and &ldquo;User Attributes&rdquo; shall have the
            same meaning as given in the EU General Data Protection Regulation
            (&ldquo;GDPR&rdquo;).
          </span>
        </li>
        <li>
          <span>You shall own all 2</span>
          <span>nd</span>
          <span>
            &nbsp;Party Hash Data and we shall own all MyTV Super Data and the
            Segments generated upon matching these two sets of data. The parties
            agree that the information and data made available through or under
            this Agreement shall be used only for purposes specified in this
            Agreement.
          </span>
        </li>
        <li>
          <span>All 2</span>
          <span>nd</span>
          <span>
            &nbsp;Party Hash Data transmitted, processed and stored on our
            Platform, servers and systems will be removed and deleted upon
            completion of the relevant Campaign period
          </span>
          <span>.</span>
        </li>
      </ol>
      <p className="c7 c25">
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_36-0" start="11">
        <li>
          <span>Confidentiality</span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_33-0 start" type="a" start="1">
        <li>
          <span>
            The parties shall only disclose Confidential Information (as defined
            below) to those of its employees, professional advisors, on-site
            contractors, officers, directors, or those of its subsidiaries or
            affiliates that it believes have a need to know such information as
            required for the performance of this Agreement or to enforce the
            terms of this Agreement. The foregoing obligations will not restrict
            either party from disclosing Confidential Information of the other
            party pursuant to a court order from a court of competent
            jurisdiction, provided that, to the extent permitted by law, the
            party required to make such a disclosure gives reasonable prior
            written notice to the other party so that it may contest such order
            and in the event that disclosure is required, only discloses the
            portion of Confidential Information that its legal counsel advises
            is legally required. &nbsp;&ldquo;
          </span>
          <span>Confidential Information&rdquo;</span>
          <span>&nbsp;consists of :</span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_34-0 start" type="i" start="1">
        <li className="c45 c40">
          <span>
            any technical information or plans concerning our Service, our
            Platform or any of our software or other technology;{" "}
          </span>
        </li>
        <li className="c21 c40">
          <span>any financial information of the other party; </span>
        </li>
        <li className="c21 c40">
          <span>
            any data collected, including Personal Data, from the other party;
            and
          </span>
        </li>
        <li className="c21 c40">
          <span>
            other information disclosed by one party to the other party that is
            marked as confidential, or should reasonably be assumed to be
            confidential under the circumstances.{" "}
          </span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_33-0" type="a" start="2">
        <li>
          <span>
            Confidential Information does not include information that:{" "}
          </span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_35-0 start" type="i" start="1">
        <li>
          <span>
            is or becomes generally known to the public through no fault of or
            breach of the receiving party;{" "}
          </span>
        </li>
        <li>
          <span>
            is rightfully known by the receiving party at the time of disclosure
            without an obligation of confidentiality;{" "}
          </span>
        </li>
        <li>
          <span>
            is independently developed by the receiving party without use of the
            disclosing party&#39;s Confidential Information; or{" "}
          </span>
        </li>
        <li>
          <span>
            is obtained by the receiving party rightfully from a third party
            who/that has no duty of confidentiality to the disclosing party.
          </span>
        </li>
      </ol>
      <p>
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_36-0" start="12">
        <li>
          <span>Termination/Suspension</span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_41-0 start" type="a" start="1">
        <li>
          <span>We may terminate this Agreement:</span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_43-0 start" type="i" start="1">
        <li>
          <span>
            for convenience on seven (7) days&#39; written notice at any time
            ;or
          </span>
        </li>
        <li className="c21 c26">
          <span>
            immediately in the event that you fail to remedy a material breach
            of this Agreement within forty-eight (48) hours of its receipt of
            written notice thereof.
          </span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_44-0 start" type="a" start="2">
        <li>
          <span>
            We may terminate or suspend your access to or use of the Service or
            our Platform or the Segments and/or terminate this Agreement at any
            time if:{" "}
          </span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_45-0 start" type="i" start="1">
        <li className="c45 c46">
          <span>
            in our reasonable opinion, such action is necessary to prevent
            errors or harm to any system or network, or to limit our or our
            group companies&#39; liability or;{" "}
          </span>
        </li>
        <li className="c21 c46">
          <span>
            you attempt to access or use the Service or the Segments in an
            unauthorized manner, including without limitation any attempt to use
            the Service in a way that infringes our, our group companies or a
            third party&#39;s Intellectual Property Rights or damage our
            reputation.
          </span>
        </li>
      </ol>
      <p>
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_36-0" start="13">
        <li>
          <span>Choice of Law</span>
        </li>
      </ol>
      <p>
        <span>
          This Agreement will be governed by and construed in accordance with
          the laws of the Hong Kong SAR. Any legal action or proceeding arising
          under this Agreement will be brought exclusively in the Hong Kong SAR
          and the parties hereby irrevocably consent to personal jurisdiction
          and venue therein. Notwithstanding the foregoing, we shall also have
          the right, but not the obligation, to bring an action for moneys owed
          by you to us or Intellectual Property Rights infringement in any other
          court that has jurisdiction over you.
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_36-0" start="14">
        <li>
          <span>Assignment</span>
        </li>
      </ol>
      <p>
        <span>
          The rights and obligations of each party hereunder shall inure to the
          benefit of the respective successors and assigns of the parties
          hereto, provided that, except as expressly provided herein, this
          Agreement and any rights or obligations hereunder shall not be
          assigned or delegated without the prior written consent of the other
          party (which shall not be unreasonably withheld), except that, either
          party may assign this Agreement to an acquirer of all or substantially
          all of such party&#39;s assets, whether by merger, operation of law or
          otherwise, without the other party&#39;s prior written consent.&nbsp;
        </span>
      </p>
      <p>
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_36-0" start="15">
        <li>
          <span>Notice</span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_37-0 start" type="a" start="1">
        <li className="c42 c45">
          <span>
            We may communicate with you electronically any important information
            regarding your transactions or your account. We may also provide
            notice to you by posting it on our Platform, or by sending it to an
            email address or postal address that you previously provided to us.
            Website and email notices shall be considered received by you within
            24 hours of the time posted or sent; notices by postal mail shall be
            considered received within three (3) business days of the time sent.
          </span>
        </li>
        <li className="c21 c42">
          <span>
            Unless stated otherwise, you must send notices to us relating to
            these Terms and Conditions to:{" "}
          </span>
          <span>
            <a href="mailto:kenrik.chan@tvb.com.hk">k</a>
          </span>
          <span>
            <a href="mailto:kenrik.chan@tvb.com.hk">enrik.chan@tvb.com.hk</a>
          </span>
        </li>
      </ol>
      <p>
        <span></span>
      </p>
      <ol className="c8 lst-kix_list_46-0 start" start="16">
        <li>
          <span>Miscellaneous</span>
        </li>
      </ol>
      <ol className="c8 lst-kix_list_38-0 start" type="a" start="1">
        <li>
          <span>
            Our failure to enforce strict performance of any provision of this
            Agreement or to exercise any right under this Agreement shall not be
            construed as a waiver of our right.
          </span>
        </li>
        <li>
          <span>
            Should any section or part of a section within this Agreement be
            rendered void or unenforceable by any court of competent
            jurisdiction, the remaining provisions of this Agreement shall
            nevertheless be binding upon the parties with the same effect as
            though the void or unenforceable.
          </span>
        </li>
        <li>
          <span>
            You acknowledge to have read and understood the terms of the Privacy
            Statement at{" "}
          </span>
          <span>
            <a href="https://www.google.com/url?q=https://www.mytvsuper.com/en/pics&amp;sa=D&amp;ust=1572845712659000">
              https://www.mytvsuper.com/en/pics
            </a>
          </span>
          <span>
            . You agree that we may use and disclose information about you in
            accordance with the terms of the Privacy Statement and to assist in
            providing the goods or services you have requested and to improve
            the Service. You also agree to check the Privacy Statement on a
            regular basis.
          </span>
        </li>
        <li>
          <span>
            In addition to this Agreement, you acknowledge that any Campaign
            will be in accordance with and subject to any specific conditions
            notified to you or generally published by us from time to time,
            including on the relevant websites.
          </span>
        </li>
        <li>
          <span>
            Sections 5, 7-11 and 13 shall survive after termination or
            expiration of this Agreement.
          </span>
        </li>
        <li>
          <span>
            If there is any inconsistency or conflict between the English and
            Chinese versions, the English version shall prevail.
          </span>
        </li>
      </ol>
    </Fragment>
  );
};

export default TncPanel;
