export const Constants = {
    SESSION_KEY_USER_TOKEN: 'USER_TOKEN',
    ACTIVE_LANG_CODE: 'LANG_CODE',
    SIGNUP_LANG_PREFS: [{ code: 'en', value: 'eng' }, { code: 'tc', value: 'trad' }, { code: 'sc', value: 'simp' }],
    
    USER_STATUS: [
      { CODE: 'verified', VALUE: 'verified' },
      { CODE: 'pending-verification', VALUE: 'pending-verification' }
    ],
  
  };
  
  