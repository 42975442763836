import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import config from '../settings/config';
import { Constants } from '../settings/Constants';

export default ({ component: C, props: cProps, ...rest }) => {
  
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem(Constants.SESSION_KEY_USER_TOKEN) ? (
          <C {...props} {...cProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};
