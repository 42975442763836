import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Constants } from "../../settings/Constants";
import { Translate } from "react-localize-redux";

import "./UploadDataSection.css";
import TncPanel from "../../components/TncPanel/TncPanel";

export default class UploadDataSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: {},
      websiteEncrptEnabled: false,
      readAllTnC: false,
      acceptTnc: false
    };
  }

  componentDidMount() {
    var userToken = localStorage.getItem(Constants.SESSION_KEY_USER_TOKEN);
  }

  acceptTnC = evt => {
    this.setState({
      acceptTnc: !this.state.acceptTnc
    });
  };

  scrollingTNC = evt => {
    let tncAreaDom = evt.target;

    const adjuctedScrollTop = tncAreaDom.scrollTop + 150 + 300;

    if (
      adjuctedScrollTop >=
      tncAreaDom.scrollHeight - tncAreaDom.offsetHeight
    ) {
      this.setState({
        readAllTnC: true
      });
    }
  };

  render() {
    let { data, rowCount, onDataChange, onSubmit } = this.props;
    return (
      <Container>
        <Row>
          <Col>
            <h2>
              <Translate id="uploadPage.step.2.title" />
            </h2>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <Translate id="uploadPage.step.2.description" />
          </Col>
        </Row> */}
        <div className="sm-line-break" />
        <Row>
          <Col>
            <Translate id="uploadPage.step.2.encryption" />
            <Form.Control
              as="textarea"
              value={data}
              onChange={onDataChange}
              className="encrypt-textarea"
            ></Form.Control>
            <Translate id="uploadPage.step.2.itemCount"/>{rowCount}
          </Col>
          <Col>
            <Form.Check
              type="checkbox"
              onClick={this.acceptTnC}
              checked={this.state.acceptTnc}
              disabled={!this.state.readAllTnC}
              label={<Translate id="uploadPage.acceptTnC" />}
              className="custom-checkbox"
            />
            <div className="tnc-area" onScroll={this.scrollingTNC}>
              <TncPanel />
            </div>
          </Col>
        </Row>
        <div className="sm-line-break" />
        <Row>
          <Col>
            <Button
              onClick={onSubmit}
              disabled={
                !this.state.acceptTnc || !this.state.readAllTnC || !data
              }
              className="custom-button"
            >
              <Translate id="uploadPage.button" />
            </Button>
          </Col>
        </Row>
        <div className="sm-line-break" />
      </Container>
    );
  }
}
