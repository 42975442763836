import moment from "moment";

export const Utils = {
  parseJwt,
  convertUtcToLocalDate,
  parseDateToMySQLDatetime
};

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}


function convertUtcToLocalDate(utcDate) {
  try {
    if (!utcDate) {
      throw new Error();
    }

    const aMoment = new moment(utcDate);
    if (!aMoment.isValid()) {
      throw new Error();
    }
    return aMoment.local().toDate();
  } catch (error) {
    return undefined;
  }
}


function parseDateToMySQLDatetime(date){
    let newdate =date.toISOString().slice(0, 19).replace('T', ' ');
    return newdate;
}