import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { Constants } from '../../settings/Constants';
import { Translate } from 'react-localize-redux';
import sha256 from 'js-sha256';
import './HashModal.css';
import { delay } from 'q';
import arrow from '../../img/arrow.png';

export default class HashModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unhashedData: '',
      preProcessRowCount: 0,
      postProcessRowCount: 0,
      campaignKey: '',
      hashedData: '',
      loading: false
    };
  }

  componentDidMount() {
    var userToken = localStorage.getItem(Constants.SESSION_KEY_USER_TOKEN);
  }

  onCampaignKeyChange = evt => {
    this.setState({
      campaignKey: evt.target.value
    });
  };

  insertFile = async evt => {
    let {showPromptMessage} = this.props

    this.setState({
      unhashedData: 'Reading file...'
    });

    let content = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsText(evt.target.files[0]);
    }).catch(err => console.log(err));

    let contentArray = [];
    if (content) {
      let lines = content.split(/[\n\r]+/);
      for (let i = 0 ; i < lines.length ; i++) {
        let line = lines[i];
        if (!line.match(/^[0-9]{8}$/) && 
          !line.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
            showPromptMessage({ key: 'error.invalid-file-content', data: { index:i+1, line }});

            this.setState({
              loading: false,
              unhashedData: ''
            });
            return;
        }
        line = line.trim().toLowerCase();
        if (line.length === 0) continue;
        contentArray.push(line);
      }
    }

    this.setState({
      unhashedData: contentArray.join('\n'),
      preProcessRowCount: contentArray.length
    });
  };

  startEncryption = async () => {
    let { showPromptMessage } = this.props;
    let { campaignKey } = this.state;

    if (!campaignKey) {
      showPromptMessage({ key: `error.empty-campaign-key` });
      return;
    }

    if (!/^[A-Za-z0-9]{8}$/.test(campaignKey)) {
      showPromptMessage({ key: `error.invalid-campaign-key` });
      return;
    }

    let rawArray = this.state.unhashedData.split(/[\n\r]+/);
    let hashedArray = [];
    this.setState({
      loading: true,
      hashedData: `Processing (0/ ${rawArray.length})`
    });

    for (let i = 0; i < rawArray.length; i++) {
      hashedArray.push(sha256(`${campaignKey}${rawArray[i]}`));

      if (i % 1000 === 0) {
        this.setState({
          loading: true,
          hashedData: `Encrypting... (${i}/ ${rawArray.length})`
        });
        await delay(100);
      }
    }

    this.setState({
      loading: true,
      hashedData: `Processing...`
    });
    await delay(100);

    this.setState({
      loading: false,
      hashedData: hashedArray.join('\n'),
      postProcessRowCount: hashedArray.length
    });
  };

  copyAndClose = () => {
    let copyText = document.getElementById('hashedData-textarea');
    copyText.select();
    document.execCommand('copy');

    this.props.onDataTransfer(this.state.hashedData, this.state.postProcessRowCount);

    this.setState({
      unhashedData: '',
      hashedData: '',
      postProcessRowCount: 0,
      preProcessRowCount: 0
    });

    this.props.onDismiss(this.state.hashedData);
  };

  render() {
    let { show } = this.props;
    return (
      <Modal show={show} onHide={this.props.onDismiss} size="xl" centered id="change-log-modal">
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>
          <div className="hash-modal">
            <Container>
              <Row>
                <Col xs={2} className="text-right">
                  <i className="fas fa fa-info-circle fa-3x" />
                </Col>
                <Col xs={10}>
                  <Translate id="encrypt.info" />
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={{ offset: 1, span: 10 }}>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="2">
                      <Translate id="encrypt.campaignKey" />
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control className="custom-input-field" placeholder="" value={this.state.campaignKey} onChange={this.onCampaignKeyChange} />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={{ offset: 1, span: 4 }}>
                  <Translate id="encrypt.1.description" />
                </Col>
                <Col xs={2}>
                  <Translate id="encrypt.2.description" />
                </Col>
                <Col xs={4}>
                  <Translate id="encrypt.3.description" />
                </Col>
              </Row>
              <div className="sm-line-break" />
              <Row className="text-center">
                <Col xs={{ offset: 1, span: 4 }}>
                  <Form.Group>
                    <Form.Control id="custom-file-upload" type="file" onChange={this.insertFile} className="custom-file-upload" />
                    <Form.Label for="custom-file-upload" className="custom-button">
                      <Translate id="encrypt.insertFile" />
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={2}>
                  <Button onClick={this.startEncryption} className="custom-button">
                    <Translate id="encrypt.start" />
                  </Button>
                </Col>
                <Col xs={4}>
                  <Button onClick={this.copyAndClose} className="custom-button">
                    <Translate id="encrypt.copyAndClose" />
                  </Button>
                </Col>
              </Row>
              <div className="sm-line-break" />
              <Row className="align-items-center">
                <Col xs={{ offset: 1, span: 4 }}>
                  <Form.Control as="textarea" value={this.state.unhashedData} className="unhashedData-textarea"></Form.Control>
                  <Translate id="encrypt.pre-process-row-count" />{this.state.preProcessRowCount}
                </Col>
                <Col xs={2}>
                  <div
                    style={{
                      background: `no-repeat url(` + arrow + `)`,
                      backgroundSize: `100%`
                    }}
                    className="arrow-background"
                  >
                    <div className="arrow-text">
                      <Translate id="encrypt.arrow-text" />
                    </div>
                  </div>
                </Col>
                <Col xs={4}>
                  <Form.Control as="textarea" readOnly className="hashedData-textarea" value={this.state.hashedData} id="hashedData-textarea"></Form.Control>
                  <Translate id="encrypt.post-process-row-count" />{this.state.postProcessRowCount}
                </Col>
              </Row>
            </Container>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
