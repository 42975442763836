import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
// import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import { unregister } from './registerServiceWorker';

ReactDOM.render(
  <LocalizeProvider>
    <Router>
      <App />
    </Router>
  </LocalizeProvider>,
  document.getElementById('root')
);

unregister();
