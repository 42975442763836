import React from 'react';
import { Switch } from 'react-router-dom';
import AppliedRoute from './AppliedRoute';
import PrivateRoute from './PrivateRoute';

import LoginPage from '../containers/LoginPage/LoginPage';
import Dashboard from '../containers/Dashboard/Dashboard';
import UserProvisionPage from '../containers/UserProvisionPage/UserProvisionPage';

/* 
	normal public page use AppliedRoute
	private page use PrivateRoute
 */
export default ({ childProps }) => {
  return (
    <Switch>
      <AppliedRoute path={'/'} exact component={LoginPage} props={childProps} />
      <PrivateRoute path={'/dashboard'} exact component={Dashboard} props={childProps} />
      <PrivateRoute path={'/users'} exact component={UserProvisionPage} props={childProps} />
    </Switch>
  );
};
