let myConfig = {
  version: '1.0.2',
  isDev: false,
  loginIdleTimeout: 1800000, //in ms
  apiUrl: "https://mytvs-2pd-backend-dev-q6u4w3hdva-an.a.run.app"
}

if (process.env.REACT_APP_MYTVS_2PD_PORTAL_ENV === 'prd'){
  myConfig.apiUrl= "https://mytvs-2pd-backend-prd-q6u4w3hdva-an.a.run.app";
} else if (process.env.REACT_APP_MYTVS_2PD_PORTAL_ENV === 'local'){
  myConfig.apiUrl= "http://localhost:3005";
}

console.log(`env:${process.env.REACT_APP_MYTVS_2PD_PORTAL_ENV}`);

export default myConfig;