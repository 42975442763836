import React, { Fragment } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

const EncryptDataButtons = props => {
  let { onHash, onDownload } = props;

  return (
    <Fragment>
      <Row>
        <Col>
          <h2>
            <Translate id="uploadPage.step.1.title" />
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Translate id="uploadPage.step.1.description" />
        </Col>
      </Row>
      <div className="sm-line-break" />
      <Row>
        <Col xs={5}>
          <Button onClick={onHash} className="custom-button">
            <Translate id="uploadPage.onSite" />
          </Button>
        </Col>
        <Col xs={2}>
          <Translate id="uploadPage.or" />
        </Col>
        <Col xs={5}>
          <Button onClick={onDownload} className="custom-button">
            <Translate id="uploadPage.offline" />
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default EncryptDataButtons;
