import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Constants } from "../../settings/Constants";
import { Translate, withLocalize } from "react-localize-redux";
import EncryptDataButtons from "../../components/EncryptDataButtons/EncryptDataButtons";
import UploadDataSection from "../UploadDataSection/UploadDataSection";
import HashModal from "../HashModal/HashModal";
import api from "../../services/api";
import config from "../../settings/config";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: {},
      data: "",
      showHashModal: false,
      rowCount: 0
    };
  }

  async componentDidMount() {
    var userToken = localStorage.getItem(Constants.SESSION_KEY_USER_TOKEN);
    const respData = await api(
      "GET",
      `${config.apiUrl}/user-sessions`,
      {},
      { customHandler: this.onLoginFailed }
    );
    console.log(respData);
    if (!respData) {
      return;
    }
  }

  onLoginFailed = error => {
    let { showPromptMessage } = this.props;
    showPromptMessage({ key: `error.${error.message}` }, "error", this.logout);
  };

  submitForm = async () => {
    const respData = await api(
      "POST",
      `${config.apiUrl}/data`,
      {
        data: this.state.data
      },
      { customHandler: this.onSubmitFailed }
    );
    if (!respData) return;
    if (respData.data.ok) {
      this.props.showPromptMessage("Success", "success", this.logout);
    }
  };

  onSubmitFailed = error => {
    let { showPromptMessage } = this.props;
    showPromptMessage({ key: `error.${error.message}` });
  };

  logout = result => {
    localStorage.removeItem(Constants.SESSION_KEY_USER_TOKEN);
    this.props.history.push("/");
  };

  render() {
    let { showPromptMessage } = this.props;
    let { data, rowCount, showHashModal } = this.state;
    return (
      <Container>
        <Row className="page-title">
          <Col>
            <h2>
              <Translate id="dashboard.title" />
            </h2>
          </Col>
        </Row>
        <EncryptDataButtons
          onHash={() => {
            this.setState({ showHashModal: true });
          }}
          onDownload={() => {
            window.location.href =
              "http://52.74.219.134/DataHashingApp/DataHashingApp.exe";
          }}
        />
        <hr />
        <UploadDataSection
          data={data}
          rowCount={rowCount}
          onDataChange={ev =>
            this.setState({
              data: ev.target.value
            })
          }
          onSubmit={this.submitForm}
        />
        <HashModal
          show={showHashModal}
          showPromptMessage={showPromptMessage}
          onDataTransfer={(hashedData, rowCount) => {
            this.setState({ data: hashedData, rowCount });
          }}
          onDismiss={() => {
            this.setState({ showHashModal: false });
          }}
        />
      </Container>
    );
  }
}

export default withLocalize(Dashboard);
